import styled from "styled-components";

export const StyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
  }
`;

export const PaddingStyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 10px;
  padding-bottom: 30px;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 30px;
  }
`;
