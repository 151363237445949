import { notification } from "antd";

export const openNotificationWithIcon = (
  message: string,
  description: string
) => {
  notification["success"]({
    message,
    description,
  });
};
