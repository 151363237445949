import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
import translationZhCn from "./locales/zh-cn/translation.json";
import translationZhTw from "./locales/zh-tw/translation.json";

const options = {
  order: [
    "querystring",
    "cookie",
    "localStorage",

    "sessionStorage",
    "htmlTag",
    "navigator",
  ],
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",

  // cache user language
  caches: ["localStorage"],
  excludeCacheFor: ["cimode"],
  //cookieMinutes: 10,
  //cookieDomain: 'myDomain'

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    detection: options,
    // lng: "zhcn",
    fallbackLng: "zhcn",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      es: {
        translations: translationEs,
      },
      zhcn: {
        translations: translationZhCn,
      },
      zhtw: {
        translations: translationZhTw,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
