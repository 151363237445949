import { Row } from "antd";
import axios from "axios";
import _ from "lodash";
import { useState } from "react";
import { Zoom } from "react-awesome-reveal";
import { withTranslation } from "react-i18next";
import Sticky from "react-sticky-el";
import styled from "styled-components";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BASE_URL } from "../../common/constants";
import Container from "../../common/Container";
import { openNotificationWithIcon } from "../../common/utils/notifications";
import { validateEmail } from "../../common/utils/validationRules";
import { ValidationTypeProps } from "../ContactForm/types";
import DropdownNavbar from "../DropdownNavbar";
import { HeaderSection } from "./styles";

const Header = ({ t }: any) => {
  // const [visible, setVisibility] = useState(false);

  // const showDrawer = () => {
  //   setVisibility(!visible);
  // };

  // const onClose = () => {
  //   setVisibility(!visible);
  // };

  // const MenuItems = () => {
  //   const scrollTo = (id: string) => {
  //     const element = document.getElementById(id) as HTMLDivElement;
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //     setVisibility(false);
  //   };
  //   return null;
  // };

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<any | any>({});
  const [values, setValues] = useState({
    name: "",
    email: "",
    wechatid: "",
    message: "",
  });

  const isBigScreen = useMediaQuery("(min-width:600px)");

  const header = isBigScreen ? (
    <TopHeaderRow justify="space-between">
      <LeftContainer></LeftContainer>
      <CenterContainer>
        <Name>ERIC CHANG</Name>
        <Tagline>{t("Tech Realtor")}</Tagline>
      </CenterContainer>
      <RightContainer>
        <RightTextContainer>
          <RightInnerContainer>WeChat ID:</RightInnerContainer>
          <RightInnerContainer>sbright88</RightInnerContainer>
        </RightTextContainer>
        <div>
          <img
            src="/img/icons/wechat.jpg"
            alt="wechat"
            width="100px"
            height="100px"
          ></img>
        </div>
      </RightContainer>
    </TopHeaderRow>
  ) : (
    <>
      <MobileTopContainer>
        <a href="weixin://dl/chat?sbright88">
          <MobileRightTextContainer>
            <div>
              <img
                src="/img/icons/wechaticon.png"
                alt="wechat"
                width="20px"
              ></img>
            </div>
            <MobileRightInnerContainer>sbright88</MobileRightInnerContainer>
          </MobileRightTextContainer>
        </a>
      </MobileTopContainer>
      <MobileTopHeaderRow>
        <MobileCenterContainer>
          <MobileName>ERIC CHANG</MobileName>
          <MobileTagline>{t("Tech Realtor")}</MobileTagline>
        </MobileCenterContainer>
      </MobileTopHeaderRow>
    </>
  );

  const onClickSend = () => {
    setErrors(validateEmail(values));

    const returnedErrors = validateEmail(values);

    if (_.isEmpty(returnedErrors)) {
      const sendEmailUrl = `${BASE_URL}/api/sendLeadInternalEmail?leadEmail=${email}`;

      axios.get(sendEmailUrl).then(() => {
        setEmail("");
        const message = t("Success");
        const description = t("We will contact you soon");
        openNotificationWithIcon(message, description);
      });
    }
  };

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <ErrorSpan erros={errors[type]}>{ErrorMessage}</ErrorSpan>
      </Zoom>
    );
  };

  return (
    <HeaderSection>
      <Container>
        {header}
        <DropdownNavbar />
      </Container>
      <Sticky>
        <EmailInputContainer>
          <div>{t("Latest New")}</div>
          <div>
            <ValidationType type="email" />
          </div>
          <EmailInputInnerContainer>
            <TextField
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setValues((values) => ({
                  ...values,
                  [event.target.name]: event.target.value,
                }));
                setErrors(validateEmail(values));
              }}
            ></TextField>
            <SendButtonContainer>
              <Button variant="contained" onClick={onClickSend}>
                {t("Submit")}
              </Button>
            </SendButtonContainer>
          </EmailInputInnerContainer>
        </EmailInputContainer>
      </Sticky>
    </HeaderSection>
  );
};

export const ErrorSpan = styled("span")<any>`
  display: block;
  font-weight: 600;
  color: rgb(255, 130, 92);
  padding: 0 0.675rem;
`;

export const EmailInputContainer = styled.div`
  float: right;
  background-color: #eff0f1;
  padding: 10px;
`;
export const EmailInputInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SendButtonContainer = styled.div`
  padding: 5px;
`;

export const TopHeaderRow = styled(Row)`
  margin-bottom: 5px;
`;

export const MobileTopHeaderRow = styled("div")`
  padding-top: 10px;
  margin-bottom: 5px;
`;

export const LeftContainer = styled("div")`
  width: 120px;
`;

export const CenterContainer = styled("div")``;

export const MobileCenterContainer = styled("div")``;

export const RightContainer = styled("div")`
  float: right;
  padding-right: 10px;
`;

export const RightTextContainer = styled("div")`
  padding-right: 10px;
`;

export const MobileRightTextContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const MobileTopContainer = styled("div")``;

export const RightInnerContainer = styled("div")`
  font-size: 20px;
  font-weight: 700;
`;

export const MobileRightInnerContainer = styled("div")`
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
`;

export const Name = styled("div")`
  font-size: 60px;
  font-weight: 700;
  font-family: "Motiva Sans Bold", serif;
  display: flex;
  justify-content: center;
`;

export const MobileName = styled("div")`
  font-size: 40px;
  font-weight: 700;
  font-family: "Motiva Sans Bold", serif;
  display: flex;
  justify-content: center;
`;

export const Tagline = styled("div")`
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
`;

export const MobileTagline = styled("div")`
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
`;

export default withTranslation()(Header);
