// styles
import "./App.css";

import { useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuItems from "./MenuItems";

const DropdownNavbar = ({ t, i18n }: any) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const lngProvided = queryParameters.get("lng");

  let lngSet = "zhcn";
  if (lngProvided) {
    lngSet = lngProvided;
  }

  const [langValue, setLangValue] = useState(lngSet);

  const handleChange = (e: any) => {
    const language = e.target.value;

    setLangValue(language);
    i18n.changeLanguage(language);
  };

  const items = [
    {
      title: t("About"),
      url: "/",
    },
    {
      title: t("Buy"),
      url: "/buy",
      submenu: [
        {
          title: t("Buy Process"),
          url: "/buy",
        },
      ],
    },
    {
      title: t("House Info"),
      url: "/permit-office",
      submenu: [
        {
          title: t("Permit Office Title"),
          url: "/permit-office",
        },
      ],
    },
  ];

  const isBigScreen = useMediaQuery("(min-width:600px)");

  const selectionContainer = (
    <SelectionContainer>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={langValue}
          onChange={handleChange}
          sx={{
            color: "white",
            "& .MuiSvgIcon-root": {
              color: "white",
            },
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
          }}
        >
          <MenuItem value={"zhcn"}>
            <MenuItemText isBigScreen={isBigScreen}>简体中文</MenuItemText>
          </MenuItem>
          <MenuItem value={"zhtw"}>
            <MenuItemText isBigScreen={isBigScreen}>繁體中文</MenuItemText>
          </MenuItem>
          <MenuItem value={"en"}>
            <MenuItemText isBigScreen={isBigScreen}>English</MenuItemText>
          </MenuItem>
        </Select>
      </FormControl>
    </SelectionContainer>
  );

  const langSelector = isBigScreen ? (
    selectionContainer
  ) : (
    <MenuItemsLi className="menu-items">{selectionContainer}</MenuItemsLi>
  );

  // <MenuItemsLi className="menu-items">
  //     <Button isBigScreen={isBigScreen}>简体中文</Button>
  //     <LiContainer>
  //       <Li>
  //         <LiAnchor href="one">简体中文</LiAnchor>
  //       </Li>
  //       <Li>
  //         <LiAnchor>繁體中文</LiAnchor>
  //       </Li>
  //       <Li>
  //         <LiAnchor href="three">English</LiAnchor>
  //       </Li>
  //     </LiContainer>
  //   </MenuItemsLi>

  return (
    <Nav>
      <Navbar>
        <Menus className="menus">
          {items.map((menu, index) => {
            const depthLevel = 0;
            return (
              <MenuItems items={menu} key={index} depthLevel={depthLevel} />
            );
          })}
          {langSelector}
        </Menus>
      </Navbar>
    </Nav>
  );
};

export default withTranslation()(DropdownNavbar);

export const Nav = styled("nav")`
  background-color: #126ec8;
`;

export const Menus = styled.div`
  display: flex;
`;

export const MenuItemsLi = styled.li`
  z-index: 2;
`;

export const MenuItemText = styled.div<{ isBigScreen: boolean }>`
  font-size: ${(props) => (props.isBigScreen ? "16px" : "14px")};
`;

export const Navbar = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const SelectionContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const LiAnchor = styled.a`
  color: white;
`;

export const Button = styled.button<{ isBigScreen: boolean }>`
  background: none;
  border: none;
  text-decoration: none;
  color: white;
  font-size: ${(props) => (props.isBigScreen ? "16px" : "14px")};
  cursor: pointer;
  outline: none;
  &:hover + div {
    opacity: 1;
    pointer-events: all;
  }
  &:focus + div {
    opacity: 1;
    pointer-events: all;
  }
`;

export const LiContainer = styled("div")`
  position: absolute;
  background: #126ec8;
  margin-top: 10px;

  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  opacity: 0;
  pointer-events: none;
  padding: 0;
`;

export const Li = styled("li")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
