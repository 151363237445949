const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/buy"],
    component: "Buy",
  },
  {
    path: ["/permit-office"],
    component: "HouseInfo/PermitOffice",
  },
];

export default routes;
