import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { PaddingStyledContainer } from "../../common/Container/styles";
import { SvgIcon } from "../../common/SvgIcon";
import { FooterSection, Language } from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <PaddingStyledContainer>
          <TopFooterContainer>
            <Row justify="space-between">
              <Col lg={16} md={16} sm={24} xs={24}>
                <Language>{t("Signature")}</Language>
                <LineItem>eric.chang@exprealty.com</LineItem>
                <LineItem>Wechat: sbright88</LineItem>
                <LineItem>Line: hummingbird88</LineItem>
                <LineItem>San Mateo, California</LineItem>
              </Col>
            </Row>
          </TopFooterContainer>
          <SocialContainer>
            <IndividualSocialContainer>
              <img
                src="/img/icons/littleredbook.png"
                alt="bay-area-overview"
                width={"25px"}
              ></img>
              <SocialDescription>
                <LineItemAnchor
                  href={
                    "https://www.xiaohongshu.com/user/profile/60a3ec30000000000101f9a6?xhsshare=CopyLink&appuid=60a3ec30000000000101f9a6&apptime=1677632184"
                  }
                  target="_blank"
                >
                  {t("Footer Signature")}
                </LineItemAnchor>
              </SocialDescription>
            </IndividualSocialContainer>
          </SocialContainer>
          <BottomFooterSection></BottomFooterSection>
        </PaddingStyledContainer>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);

export const TopFooterContainer = styled.div`
  padding-top: 20px;
`;

export const BottomFooterSection = styled.div`
  height: 20px;
`;

export const SocialContainer = styled.div`
  padding-top: 10px;
`;

export const IndividualSocialContainer = styled.div`
  display: flex;
`;

export const SocialDescription = styled.div`
  padding-left: 5px;
`;

export const LineItemAnchor = styled.a`
  color: #18216d;
  font-size: 16px;
`;

export const LineItem = styled("div")`
  color: #18216d;
  font-size: 16px;
`;
