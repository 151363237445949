import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";

import { Link } from "react-router-dom";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const isBigScreen = useMediaQuery("(min-width:600px)");

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
      isBigScreen={isBigScreen}
    >
      {items.url && items.submenu ? (
        <>
          <Button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
            isBigScreen={isBigScreen}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <StyledLink isBigScreen={isBigScreen} to={items.url}>
                {items.title}
              </StyledLink>
            )}

            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel >
                0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </Button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <Button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{" "}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </Button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <StyledLink isBigScreen={isBigScreen} to={items.url}>
          {items.title}
        </StyledLink>
      )}
    </li>
  );
};

export default MenuItems;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  font-size: ${(props) => (props.isBigScreen ? "16px" : "14px")};
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  color: white;
  font-size: ${(props) => (props.isBigScreen ? "16px" : "14px")};
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
`;

export const Li = styled.li`
  background: none;
  border: none;
  text-decoration: none;
  color: white;
  font-size: ${(props) => (props.isBigScreen ? "16px" : "14px")};
  cursor: pointer;
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
`;
